<template>
  <div class="sports-wrapper">
    <div
      v-if="!filteredSportsByEntityIds.length"
      data-t="no-events"
      class="no-data"
    >
      <slot name="empty-list">
        {{ t('sportList.noData') }}
      </slot>
    </div>
    <template v-else>
      <MSportListItem
        v-for="(item, idx) in filteredSportsByEntityIds"
        :key="item.sport.entityId"
        :per-page="idx < 2 ? MAX_PER_PAGE : MIN_PER_PAGE"
        :sport-tree="item"
        :is-single-sport="props.isSingleSport"
        :active-filter="props.activeFilter"
        :hide-markets="props.hideMarkets"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import MSportListItem from '../SportListItem/MSportListItem.vue'
import type { RenderFullTree, MobileMarketsFilterType } from '../../types'
import { useSportList } from './useSportList'

const MIN_PER_PAGE = 2
const MAX_PER_PAGE = 8

interface Props {
  renderFullTree: RenderFullTree[]
  isSingleSport?: boolean
  activeFilter?: MobileMarketsFilterType
  hideMarkets?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  renderFullTree: () => [],
  isSingleSport: false,
  activeFilter: undefined,
  hideMarkets: false,
})

const { t } = useI18n()
const { renderFullTree } = toRefs(props)
const { filteredSportsByEntityIds } = useSportList(renderFullTree)
</script>

<style scoped>
.no-data {
  margin-top: var(--spacing-200);
  text-align: center;
}

.sports-wrapper {
  background: var(--background-base);
  border-radius: var(--border-radius-200) var(--border-radius-200) 0 0;
}
</style>
